<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        اضافه جديد
      </template>
      <template #right>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/ascorchit')"
        />
      </template>
    </Toolbar>
    <div class="form-card">
      <b-progress :value="progress" variant="success"></b-progress>

      <b-card
        v-if="current_step == 1"
        class="card-style"
        title="البيانات الاساسيه"
      >
        <div class="mb-3">
          <label for="leaguesId" class="form-label">
            المسابقه
          </label>
          <select
            class="form-select form-control"
            id="leaguesId"
            name="leaguesId"
            v-model="body.leaguesId"
          >
            <option
              v-for="item of leaguesList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
        </div>
        <div class="mb-3">
          <label for="conutMatches" class="form-label">
            عداد المباريات
          </label>
          <input
            type="number"
            class="form-control"
            id="conutMatches"
            v-model.number="body.conutMatches"
            min="0"
          />
        </div>
        <div class="mb-3">
          <label for="year" class="form-label">
            الجدول الزمني لدوري الاكاديميات الخاصه لكره القدم النسخه
          </label>
          <input class="form-control" id="year" v-model="body.year" />
        </div>
        <div class="mb-3">
          <label for="undier" class="form-label">
            تحت رعايه
          </label>
          <input class="form-control" id="undier" v-model="body.undier" />
        </div>
        <div class="mb-3">
          <label for="day" class="form-label">
            اليوم
          </label>
          <input class="form-control" type="date" id="day" v-model="body.day" />
        </div>

        <b-button class="float-right" variant="primary" @click="nextStepTwo"
          >التالي</b-button
        >
      </b-card>

      <b-card
        v-if="current_step == 2"
        class="card-style"
        title="تحديد المواليد"
      >
        <div class="mb-3">
          <label for="yearsList" class="form-label">
            تحديد المواليد
          </label>
          <div v-for="(itm, index) of body.yearsList" :key="'years_' + index">
            <label for="yearsList" class="form-label">
              مباره {{ index + 1 }}
            </label>
            <select
              class="form-select form-control"
              name="yearsList[]"
              v-model="itm.value"
            >
              <option v-for="item of yearsList" :key="item.id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <b-button class="float-left" variant="secondary" @click="onClickBack"
          >رجوع</b-button
        >
        <b-button class="float-right" variant="primary" @click="nextStepThree"
          >التالي</b-button
        >
      </b-card>

      <b-card
        v-if="current_step == 3"
        class="card-style"
        title="تحديد الاكاديميات المشاركه"
      >
        <div class="mb-3">
          <label for="academsList" class="form-label">
            تحديد الاكاديميات المشاركه
          </label>
          <div
            v-for="(itm, index) of body.academsList"
            :key="'academs_' + index"
          >
            <label for="academsList" class="form-label">
              مباره {{ index + 1 }} - (
              {{
                yearsList.find((el) => el.id == body.yearsList[index].value)
                  .name
              }})
            </label>
            <div class="row">
              <div class="col-md-6">
                <select
                  class="form-select form-control"
                  name="academsList[]"
                  v-model="itm.team1"
                >
                  <option
                    v-for="item of academsList[index]"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.userName }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <select
                  class="form-select form-control"
                  name="academsList[]"
                  v-model="itm.team2"
                >
                  <option
                    v-for="item of academsList[index]"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.userName }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <b-button class="float-left" variant="secondary" @click="onClickBack"
          >رجوع</b-button
        >
        <b-button class="float-right" variant="primary" @click="nextStepFour"
          >التالي</b-button
        >
      </b-card>

      <b-card v-if="current_step == 4" class="card-style" title="تحديد الفرق">
        <div class="mb-3">
          <label for="teamsList" class="form-label">
            تحديد الفرق
          </label>
          <div v-for="(itm, index) of body.teamsList" :key="'teams_' + index">
            <label for="teamsList" class="form-label">
              (
              {{
                yearsList.find((el) => el.id == body.yearsList[index].value)
                  .name
              }}) مباره {{ index + 1 }} ({{
                academsList[index].find(
                  (academs) => academs.id == body.academsList[index].team1,
                ).userName
              }}
              -
              {{
                academsList[index].find(
                  (academs) => academs.id == body.academsList[index].team2,
                ).userName
              }})
            </label>
            <div class="row">
              <div class="col-md-6">
                <select
                  class="form-select form-control"
                  name="teamsList[]"
                  v-model="itm.team1"
                >
                  <option
                    v-for="item of teamsList1[index]"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-md-6">
                <select
                  class="form-select form-control"
                  name="teamsList[]"
                  v-model="itm.team2"
                >
                  <option
                    v-for="item of teamsList2[index]"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <b-button class="float-left" variant="secondary" @click="onClickBack"
          >رجوع</b-button
        >
        <b-button class="float-right" variant="primary" @click="save"
          >حفظ</b-button
        >
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      current_step: 1,
      max_step: 5,
      leaguesList: [],
      body: {
        leaguesId: null,
        year: null,
        undier: null,
        day: null,
        conutMatches: 0,
        yearsList: [],
        academsList: [],
        teamsList: [],
      },
      yearsList: [],
      academsList: [],
    };
  },
  methods: {
    onClickNext: function() {
      this.current_step++;
    },
    onClickBack: function() {
      this.current_step--;
    },
    nextStepTwo() {
      if (
        this.body.leaguesId &&
        this.body.year &&
        this.body.undier &&
        this.body.day &&
        this.body.conutMatches > 0
      ) {
        this.body.yearsList = [];
        this.body.academsList = [];
        this.body.teamsList = [];
        for (let i = 1; i <= this.body.conutMatches; i++) {
          this.body.yearsList.push({ value: null });
          this.body.academsList.push({ team1: null, team2: null });
          this.body.teamsList.push({ team1: null, team2: null });
        }
        this.current_step++;
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يجب ملاء الحقول',
          life: 3000,
        });
      }
    },
    async nextStepThree() {
      if (
        this.body.yearsList.map((el) => el.value == null).indexOf(true) == -1
      ) {
        this.academsList = [];
        let index = 0;
        for (const item of this.body.yearsList) {
          const res = await this.$http.get(
            `years/findAllYearsAcademie?leaguesId=${this.body.leaguesId}&yearId=${item.value}`,
          );
          if (res.data && res.data.length > 0) {
            this.academsList[index] = await res.data
              .map((el) => el.academieId)
              .filter((el) => el.deletedAt == null);
          }
          await index++;
        }

        await this.current_step++;
        return await true;
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يجب ملاء الحقول',
          life: 3000,
        });
        return await true;
      }
    },
    async nextStepFour() {
      if (
        this.body.academsList.map((el) => el.team1 == null).indexOf(true) ==
          -1 &&
        this.body.academsList.map((el) => el.team2 == null).indexOf(true) ==
          -1
      ) {
        this.teamsList1 = [];
        this.teamsList2 = [];
        let index = 0;
        for (const item of this.body.academsList) {
          const res = await this.$http.get(
            `years/findAllYearsAcademie?leaguesId=${this.body.leaguesId}&yearId=${this.body.yearsList[index].value}&academieId=${item.team1}`,
          );

          if (res.data && res.data.length > 0) {
            const list1 = await res.data.map((i) => i.yearsTeams);
            this.teamsList1[index] = (await list1[0]) ? list1[0] : [];
          }

          const res2 = await this.$http.get(
            `years/findAllYearsAcademie?leaguesId=${this.body.leaguesId}&yearId=${this.body.yearsList[index].value}&academieId=${item.team2}`,
          );
          if (res2.data && res2.data.length > 0) {
            const list2 = await res2.data.map((i) => i.yearsTeams);
            this.teamsList2[index] = (await list2[0]) ? list2[0] : [];
          }
          await index++;
        }

        await this.current_step++;
        return await true;
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يجب ملاء الحقول',
          life: 3000,
        });

        return await true;
      }
    },
    save() {
      if (
        this.body.teamsList.map((el) => el.team1 == null).indexOf(true) ==
          -1 &&
        this.body.teamsList.map((el) => el.team2 == null).indexOf(true) == -1
      ) {
        this.$http
          .post(`ascorchit`, {
            leaguesId: this.body.leaguesId,
            conutMatches: this.body.conutMatches,
            yearsList: JSON.stringify(
              this.body.yearsList.map((el) => el.value),
            ),
            academsList: JSON.stringify(this.body.academsList),
            teamsList: JSON.stringify(this.body.teamsList),
            year: this.body.year,
            undier: this.body.undier,
            day: this.body.day,
          })
          .then(
            () => {
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم الاضافه بنجاح',
                life: 3000,
              });
              this.$router.push('/admin/ascorchit');
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      } else {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يجب ملاء الحقول',
          life: 3000,
        });
      }
    },
  },
  created() {
    this.$http.get(`league`).then((response) => {
      this.leaguesList = response.data;

      this.body.leaguesId = this.leaguesList[0]?.id;
      if (this.body.leaguesId) {
        this.$http.get(`years?leaguesId=${this.body.leaguesId}`).then((res) => {
          this.yearsList = res.data;
        });
      }
    });
  },
  computed: {
    progress: function() {
      return Math.round(100 / this.max_step) * this.current_step;
    },
  },
  watch:{
    'body.leaguesId'(val){
      if (val) {
        this.$http.get(`years?leaguesId=${val}`).then((res) => {
          this.yearsList = res.data;
        });
      }
    }
  }
};
</script>

<style></style>
